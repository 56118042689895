export const alignMap = {
  left: 'start',
  center: 'center',
  right: 'end',
  top: 'start',
  middle: 'center',
  bottom: 'end',
}

export const formatLink = (url, label, val, idx) => {
  if (!url) return ''
  if (!url.includes(label + '=')) {
    let symbol = url.includes('?') ? '&' : '?'
    return `${url}${symbol}ref=${val}${idx !== undefined ? idx + 1 : ''}`
  }
  return url
}

export function numberFormat(num = 0) {
  if (typeof Intl === 'undefined') {
    return num
  }

  return new Intl.NumberFormat().format(num)
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0
}

export function refLink(link, title = '', ref) {
  if (!link) return undefined
  const cleanTitle = title
    .replace(/[?？]/g, '')
    .replace(/[!！]/g, '')
    .replace(/\<.+\>/, '')
    .replace(/\<\/.+\>/, '')
  const refTitle = cleanTitle.toLowerCase().split(' ').slice(0, 3).join('_')
  const refParam = ref ? `_${ref}` : ''
  const refQuery = `ref=homepage_${refTitle}${refParam}`
  const separator = link.includes('?') ? '&' : '?'
  return `${link}${separator}${refQuery}`
}
