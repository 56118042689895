const ArrowRight = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <circle cx="24" cy="24" r="24" fill="#EEEEEE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2 30.634C21.6471 31.122 22.378 31.122 22.8252 30.634L28.7879 24.1265C29.2282 23.646 29.2282 22.8718 28.7879 22.3913L22.8252 15.8838C22.378 15.3958 21.6471 15.3958 21.2 15.8838C20.7596 16.3643 20.7596 17.1385 21.2 17.619L26.3677 23.2589L21.2 28.8987C20.7596 29.3793 20.7596 30.1534 21.2 30.634Z"
        fill="#6E6E6E"
      />
    </svg>
  )
}

export default ArrowRight
