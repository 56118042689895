import { Swiper, SwiperSlide } from 'swiper/react'
import s from '../../Home.module.css'
import cn from 'classnames'
import { Picture, Link, Text } from '@components/ui'
import Arrow from '../Arrow'
import { refLink } from '../../utils'
import useProduct from '@shopify/product/use-product'
import { useCallback, useState, useEffect } from 'react'
import { NextArrow, PrevArrow } from '../Components/SwiperArrow'
import { Mousewheel } from 'swiper'

/**
 * Renders a slide component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale.
 * @param {Object} props.shopCommon - The common shop data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The rendered slide component.
 */
const Slide = ({
  section,
  locale,
  shopCommon,
  relatedProducts,
  className,
  boxClassName,
  paddingClassName,
}) => {
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)
  const [swiper, setSwiper] = useState()

  const [isMobile, setIsMobile] = useState(false)
  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth < 1024) return setIsMobile(true)
    setIsMobile(false)
  }
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const handleProgress = (swiper) => {
    setStart(swiper.isBeginning)
    setEnd(swiper.isEnd)
  }

  const settings = {
    modules: [Mousewheel],
    spaceBetween: section.spaceBetween || 8,
    slidesPerView: 'auto' || section.slidesPerView,
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
      },
    },
    onProgress: handleProgress,
    onSwiper: setSwiper,
    mousewheel: {
      releaseOnEdges: true,
      forceToAxis: true,
    },
  }

  const { data } = useProduct({
    handles: section.lists?.map((item) => item.handle).join(','),
  })

  const getProduct = useCallback(
    (item) => {
      const product = relatedProducts.find(
        (product) =>
          product.origin.handle === item.handle &&
          product.origin.sku === item.sku
      )
      let _product
      if (product && product.result) {
        _product = product.result
        _product.variant = product.result.variants?.find(
          (variant) => variant.sku === item.sku
        )
      } else {
        data?.products?.forEach((product) => {
          if (product.handle === item.handle) {
            product.variants?.forEach((variant) => {
              if (variant.sku === item.sku) {
                _product = product
                _product.variant = variant
              }
            })
          }
        })
      }
      return _product
    },
    [data, relatedProducts]
  )

  return (
    <div
      className={cn(
        'layer overflow-hidden',
        {
          '!overflow-visible !px-0': section.full_width,
          'min-l:hidden': section.hide_pc,
        },
        className
      )}
      style={{
        background: section.background,
      }}
    >
      <div className="content">
        <div
          className={cn(
            'pb-[var(--pb)] pt-[var(--pt)] min-l:pb-[var(--pb-l)] min-l:pt-[var(--pt-l)] min-xl:pb-[var(--pb-xl)] min-xl:pt-[var(--pt-xl)]',
            paddingClassName
          )}
          style={{
            '--pt': section.paddingTop || '20px',
            '--pb': section.paddingBottom || '20px',
            '--pt-l': section.paddingTop_l || '40px',
            '--pb-l': section.paddingBottom_l || '40px',
            '--pt-xl': section.paddingTop_xl || '60px',
            '--pb-xl': section.paddingBottom_xl || '60px',
          }}
        >
          <div
            className={cn(
              'min-l:flex min-l:items-center min-l:justify-between min-l:text-center',
              {
                hidden: section.noTitle,
              }
            )}
          >
            <h2
              className={cn(s.title)}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
            {section.all && (
              <div className="hidden min-l:block">
                <Link
                  href={refLink(section.all_link, section?.title, 'all')}
                  className="flex items-center justify-center gap-[2px] text-center font-semibold leading-[1.2] text-[#333]"
                >
                  <span>{section.all}</span>
                  <Arrow />
                </Link>
              </div>
            )}
          </div>

          {section.lists && (
            <>
              <Swiper
                {...settings}
                className={cn(
                  'mt-6 !overflow-visible min-l:mt-8 min-xl:mt-12',
                  {
                    [s.swiper2box]: section.lists.length === 2,
                    [s.swiper1box]: section.lists.length === 1,
                    '!mt-0': section.noTitle,
                  }
                )}
              >
                {section.lists?.map((item, index) => {
                  let {
                    handle,
                    sku,
                    title,
                    image,
                    image_mob,
                    image_hover,
                    learn_more,
                    buy_now,
                    learn_more_text,
                  } = item
                  if (handle && sku) {
                    const product = getProduct(item)
                    if (!product) return null
                    title = product.title
                    image =
                      product.variant?.image?.url || product.images[0]?.url
                  }
                  if (image && !image_mob) {
                    image_mob = image
                  }
                  if (image_mob && !image) {
                    image = image_mob
                  }
                  if (learn_more && buy_now) {
                    learn_more = refLink(
                      learn_more,
                      section.title,
                      'learn_' + (index + 1)
                    )
                    buy_now = refLink(
                      buy_now,
                      section.title,
                      'buynow_' + (index + 1)
                    )
                  } else {
                    if (learn_more !== undefined) {
                      learn_more = refLink(learn_more, section.title, index + 1)
                    }
                    if (buy_now !== undefined) {
                      buy_now = refLink(buy_now, section.title, index + 1)
                    }
                  }
                  return (
                    <SwiperSlide
                      key={index}
                      className={cn(
                        s.swiperSlide,
                        s.picBox,
                        {
                          [s.picBox]: !!section.hoverPic,
                          [s.hoverShow]: section.hover_show,
                          [s.imageHover]: image_hover,
                        },
                        'relative !h-auto !w-[298px] overflow-hidden rounded-xl min-l:!w-[422px] min-xl:!w-[422px] min-xxl:!w-[584px]',
                        {
                          'bg-white': section.position === 'bottom',
                        }
                      )}
                      style={{
                        '--width': section.width || '298px',
                        '--width_l': section.width_l || '422px',
                        '--width_xl': section.width_xl || '422px',
                        '--width_xxl': section.width_xxl || '584px',
                        '--grow': item.grow,
                      }}
                    >
                      {item.tag && (
                        <div className="absolute left-4 top-4 z-[1] flex flex-wrap gap-1">
                          {Array.isArray(item.tag) ? (
                            <>
                              {item.tag.map((tag, index) => (
                                <span
                                  className="rounded-full bg-[#c0ebf9] px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color"
                                  key={index}
                                >
                                  {tag}
                                </span>
                              ))}
                            </>
                          ) : (
                            <span className="rounded-full bg-[#c0ebf9] px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color">
                              {item.tag}
                            </span>
                          )}
                        </div>
                      )}
                      {learn_more ? (
                        <Link href={learn_more}>
                          <Picture
                            source={`${image}, ${image_mob} 1024`}
                            alt={title}
                            className={cn(s.oriImg, {
                              'h-full': section.position !== 'bottom',
                              'overflow-hidden rounded-xl':
                                section.position === 'bottom',
                            })}
                            imgClassName="h-full !object-cover !object-center"
                            imgWidth={832}
                            imgHeight={1036}
                          />
                          {image_hover && (
                            <Picture
                              source={image_hover}
                              alt={title}
                              className={cn(s.hoverImg, {
                                'h-full': section.position !== 'bottom',
                              })}
                              imgClassName="h-full"
                              imgWidth={832}
                              imgHeight={1036}
                            />
                          )}
                        </Link>
                      ) : (
                        <>
                          <Picture
                            source={`${image}, ${image_mob} 1024`}
                            alt={title}
                            className={cn(s.oriImg, {
                              'h-full': section.position !== 'bottom',
                              'overflow-hidden rounded-xl':
                                section.position === 'bottom',
                            })}
                            imgClassName="h-full !object-cover !object-center"
                            imgWidth={832}
                            imgHeight={1036}
                          />
                          {image_hover && (
                            <Picture
                              source={image_hover}
                              alt={title}
                              className={cn(s.hoverImg, {
                                'h-full': section.position !== 'bottom',
                              })}
                              imgClassName="h-full"
                              imgWidth={832}
                              imgHeight={1036}
                            />
                          )}
                        </>
                      )}
                      <div className={s.swiperSlideShade}></div>
                      <div
                        className={cn(
                          'absolute box-border w-full px-4 py-4 min-xl:p-6',
                          boxClassName,
                          {
                            'min-l:hidden': section.hover_show,
                            'text-white':
                              item._theme === 'dark' ||
                              (item._mob_theme === 'dark' && isMobile) ||
                              (item._pc_theme === 'dark' && !isMobile),
                          },
                          {
                            'bottom-0 left-0':
                              ['left bottom', 'bottom left'].includes(
                                section.position
                              ) || !section.position,
                            'left-0 top-1/2 -translate-y-1/2': [
                              'left center',
                              'center left',
                            ].includes(section.position),
                            'left-0 top-0': ['left top', 'top left'].includes(
                              section.position
                            ),
                            'left-0 top-0 pt-14 text-center': [
                              'center top',
                              'top center',
                            ].includes(section.position),
                            'bottom-0 left-0 text-center': [
                              'center bottom',
                              'bottom center',
                            ].includes(section.position),
                            '!static !pl-0 pb-[52px] pr-[46px] pt-[18px] leading-[1] min-xl:pb-[60px]':
                              section.position === 'bottom',
                          }
                        )}
                        style={{
                          color: item.color || section.textColor,
                        }}
                      >
                        <h4
                          className={cn(
                            'text-base font-semibold !leading-[1.2] min-xl:text-[20px] min-xxl:text-2xl'
                          )}
                          style={{
                            fontSize: section.fontSize,
                          }}
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></h4>
                        {item.description && (
                          <Text
                            className="mt-2 text-[14px] font-medium leading-[1.2] opacity-75 min-l:mt-[6px] min-xl:mt-2 min-xxl:mt-3"
                            html={item.description}
                          />
                        )}
                        {item.subtitle && (
                          <p
                            className={cn(
                              'mt-2 text-sm font-medium opacity-75 min-l:mt-[6px] min-xl:mt-2 min-xxl:mt-3'
                            )}
                            dangerouslySetInnerHTML={{ __html: item.subtitle }}
                          ></p>
                        )}
                        <div
                          className={cn(
                            'mt-2 min-l:mt-[6px] min-xl:mt-2 min-xxl:mt-3',
                            {
                              'absolute bottom-0 left-0 w-full px-[18px] !pl-0 pb-6 min-xl:px-8 min-xl:pb-8':
                                section.position === 'bottom',
                            }
                          )}
                        >
                          {buy_now != undefined ? (
                            <div className="mt-4 flex items-center justify-center gap-3">
                              {learn_more && (
                                <Link
                                  className={cn(
                                    '!whitespace-normal',
                                    s.btnBasic,
                                    {
                                      'md:text-xs':
                                        [
                                          'eu-de,',
                                          'eu-en',
                                          'nl',
                                          'eu-es',
                                          'eu-it',
                                          'eu-fr',
                                        ].includes(locale) || section.smallBtn,
                                      [s.btnWhite]:
                                        item._theme === 'dark' ||
                                        (item._mob_theme === 'dark' &&
                                          isMobile) ||
                                        (item._pc_theme === 'dark' &&
                                          !isMobile),
                                    }
                                  )}
                                  href={learn_more}
                                >
                                  <span>
                                    {item.learn_more_text ||
                                      section.learn_more ||
                                      shopCommon.learn_more}
                                  </span>
                                </Link>
                              )}
                              <Link
                                className={cn(
                                  '!whitespace-normal',
                                  s.btnPrimary,
                                  {
                                    'md:text-xs':
                                      [
                                        'eu-de,',
                                        'eu-en',
                                        'nl',
                                        'eu-es',
                                        'eu-it',
                                        'eu-fr',
                                      ].includes(locale) || section.smallBtn,
                                    [s.btnWhite]:
                                      item._theme === 'dark' ||
                                      (item._mob_theme === 'dark' &&
                                        isMobile) ||
                                      (item._pc_theme === 'dark' && !isMobile),
                                  }
                                )}
                                href={buy_now}
                              >
                                <span>
                                  {item.buy_now_text ||
                                    section.buy_now ||
                                    shopCommon.buyNow}
                                </span>
                              </Link>
                            </div>
                          ) : (
                            <>
                              {learn_more ? (
                                <Link
                                  className={cn(
                                    'flex items-center gap-[2px] text-sm font-semibold min-xl:text-base',
                                    {
                                      [s.btnPrimary]: [
                                        'center top',
                                        'top center',
                                      ].includes(section.position),
                                      [s.btnWhite]:
                                        (item._theme === 'dark' ||
                                          (item._mob_theme === 'dark' &&
                                            isMobile) ||
                                          (item._pc_theme === 'dark' &&
                                            !isMobile)) &&
                                        ['center top', 'top center'].includes(
                                          section.position
                                        ),
                                    }
                                  )}
                                  style={{
                                    color: section.textColor,
                                  }}
                                  href={learn_more}
                                >
                                  <span>
                                    {learn_more_text ||
                                      section.learn_more ||
                                      shopCommon.learn_more}
                                  </span>
                                  <Arrow
                                    className={cn({
                                      hidden: [
                                        'center top',
                                        'top center',
                                      ].includes(section.position),
                                    })}
                                  />
                                </Link>
                              ) : (
                                <span className="font-semibold text-[#333]">
                                  {learn_more_text}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className={cn(s.textBox, 'hidden', {
                          'min-l:!block': section.hover_show,
                        })}
                      >
                        <h4
                          className={cn(
                            'text-lg font-semibold leading-[1.2] min-l:text-base min-xl:text-2xl'
                          )}
                          style={{
                            fontSize: section.fontSize,
                          }}
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></h4>
                        {item.subtitle && (
                          <p
                            className={cn('mt-3 font-medium')}
                            dangerouslySetInnerHTML={{ __html: item.subtitle }}
                          ></p>
                        )}
                        <div
                          className={cn('mt-3', {
                            'absolute bottom-0 left-0 w-full px-[18px] pb-6':
                              section.position === 'bottom',
                          })}
                        >
                          <Link
                            className={cn(
                              'flex items-center gap-[2px] font-semibold text-[#333]',
                              {
                                'justify-center': [
                                  'center top',
                                  'top center',
                                ].includes(section.position),
                              }
                            )}
                            style={{
                              color: section.textColor,
                            }}
                            href={learn_more}
                          >
                            <span>
                              {learn_more_text ||
                                section.learn_more ||
                                shopCommon.learn_more}
                            </span>
                            <Arrow />
                          </Link>
                        </div>
                      </div>

                      {item.icons && (
                        <div className="absolute right-4 top-4 flex items-center gap-2">
                          {item.icons.map((icon, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-1"
                            >
                              <Picture
                                source={icon.icon}
                                imgClassName="h-7 !w-auto"
                              />
                              {icon.label && (
                                <span
                                  className="text-left text-[8px] font-semibold leading-[1.2] text-[#333]"
                                  dangerouslySetInnerHTML={{
                                    __html: icon.label,
                                  }}
                                ></span>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </SwiperSlide>
                  )
                })}
                <PrevArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
                <NextArrow
                  swiper={swiper}
                  start={start}
                  end={end}
                  title={section.title}
                />
              </Swiper>
              {/* <div
                className={cn(
                  'hidden items-center justify-end gap-4 min-l:flex',
                  {
                    '!hidden': start && end,
                  }
                )}
              >
                <PrevArrow />
                <NextArrow />
              </div> */}
            </>
          )}
          {section.all && (
            <div
              className={cn('mt-9 min-l:hidden', {
                hidden: section.hide_all_mob,
              })}
            >
              <Link
                href={refLink(section.all_link, section?.title, 'all')}
                className="flex items-center justify-center gap-[2px] text-center font-semibold leading-[1.2] text-[#333]"
              >
                <span>{section.all}</span>
                <Arrow />
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Slide
