// 优化后的箭头组件，避免了不必要的对象展开操作

const Arrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M6.19526 12.4714C5.93491 12.2111 5.93491 11.789 6.19526 11.5286L9.72386 8L6.19526 4.47141C5.93491 4.21106 5.93491 3.78895 6.19526 3.5286C6.45561 3.26825 6.87772 3.26825 7.13807 3.5286L11.1381 7.5286C11.3984 7.78895 11.3984 8.21106 11.1381 8.47141L7.13807 12.4714C6.87772 12.7318 6.45561 12.7318 6.19526 12.4714Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Arrow
