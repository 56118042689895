const ArrowLeft = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <circle
        cx="24"
        cy="24"
        r="24"
        transform="matrix(-1 0 0 1 48 0)"
        fill="#EEEEEE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8 30.634C26.3529 31.122 25.622 31.122 25.1748 30.634L19.2121 24.1265C18.7718 23.646 18.7718 22.8718 19.2121 22.3913L25.1748 15.8838C25.622 15.3958 26.3529 15.3958 26.8 15.8838C27.2404 16.3643 27.2404 17.1385 26.8 17.619L21.6323 23.2589L26.8 28.8987C27.2404 29.3793 27.2404 30.1534 26.8 30.634Z"
        fill="#6E6E6E"
      />
    </svg>
  )
}

export default ArrowLeft
