import { ArrowLeft, ArrowRight } from '@components/icons/home'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import cn from 'classnames'

const PrevArrow = ({ swiper, start, end, title }) => {
  const handleSlidePrev = () => {
    if (title) {
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'Home Page',
          position: title,
          button_name: 'prev',
        },
      })
    }
    swiper?.slidePrev()
  }

  return (
    <button
      onClick={handleSlidePrev}
      className={cn(
        'arrow absolute left-5 top-1/2 z-[1] -mt-6 hidden h-12 w-12 text-[#333]',
        {
          'pointer-events-none cursor-not-allowed opacity-0': start,
          '!opacity-0': start && end,
        }
      )}
    >
      <ArrowLeft />
    </button>
  )
}

const NextArrow = ({ swiper, start, end, title }) => {
  const handleSlideNext = () => {
    if (title) {
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'Home Page',
          position: title,
          button_name: 'next',
        },
      })
    }
    swiper?.slideNext()
  }

  return (
    <button
      onClick={handleSlideNext}
      className={cn(
        'arrow absolute right-5 top-1/2 z-[1] -mt-6 hidden h-12 w-12 text-[#333]',
        {
          'pointer-events-none cursor-not-allowed opacity-0': end,
          '!opacity-0': start && end,
        }
      )}
    >
      <ArrowRight />
    </button>
  )
}

export { PrevArrow, NextArrow }
